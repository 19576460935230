import React from 'react';
import { useState, useEffect } from "react";
import { Form } from 'react-final-form';
import { Box, Button, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { TextInput, CardContentInner, useListContext } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import SelectFinanceCoInput from '../common/SelectFinanceCoInput'

const styles = {
  card: {
    boxShadow: 'none'
  }
};

const useStyles = makeStyles(styles);

const StyledBox = props => {
  return (
    <Box
      borderColor="text.secondary"
      border={1}
      align="center"
      padding={1}
      paddingLeft={2.5}
      paddingRight={2.5}
      borderRadius={8}
      display="flex"
      alignItems="flex-end"
      mr={1}
      {...props} />
  );
};

const TryParseInt = (str, defaultValue) => {
  var retValue = defaultValue;
  if (str?.length > 0) {
    if (!isNaN(str)) {
      retValue = parseInt(str);
    }
  }
  return retValue;
}

const Filter = () => {
  const classes = useStyles();

  const {
    filterValues,
    setFilters,
    data,
    ids
  } = useListContext();

  const onSubmit = () => {
    var filters = {
      SubmissionId: TryParseInt(submissionId, null),
      FormNum: submissionIdHasValue ? null : formNum,
      FinanceCos: submissionIdHasValue || financeCo.length === 0 ? null : [financeCo]
    }
    setFilters(filters);
  };

  const AddStateLink = React.forwardRef((props, ref) => {
    return (
      <RouterLink
        ref={ref}
        to={{
          pathname: "/forms/create",
          state: {
            formNum: formNum,
            financeCo: financeCo
          }
        }}
        {...props} />
    )
  });

  var financeCoInitialValue = ""

  const [submissionIdHasValue, setSubmissionIdHasValue] = useState(false);
  const [searchBtnDisabled, setSearchBtnDisabled] = useState(true);
  const [saveBtnDisabled, setSaveBtnDisabled] = useState(true);
  const [submissionId, setSubmissionId] = useState("");
  const [formNum, setFormNum] = useState("");
  const [financeCo, setFinanceCo] = useState(financeCoInitialValue);

  const handleSubmissionIdChange = (e) => {
    const submissionId = e.target?.value;
    setSubmissionId(submissionId?.length > 0 ? submissionId : "");
    setSubmissionIdHasValue((submissionId?.length > 0) ? true : false)
    setFilters({})
  }

  const handleFormNumChange = (e) => {
    const formNum = e.target?.value;
    setFormNum(formNum?.length > 0 ? formNum : "");
    setFilters({})
  }

  useEffect(() => {
    if (submissionIdHasValue) {
      setFormNum(data[ids[0]]?.formNum ?? "")
      setFinanceCo(data[ids[0]]?.financeCo ?? financeCoInitialValue)
    }
    setSearchBtnDisabled(!submissionIdHasValue && (formNum.length === 0 || financeCo.length === 0))
    setSaveBtnDisabled((formNum.length === 0 || financeCo.length === 0))
  }, [data, ids, submissionIdHasValue, financeCoInitialValue, formNum, financeCo, submissionId])

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {props => (
          <form onSubmit={props.handleSubmit}>
            <CardContentInner className={classes.card}>
              <Box display="flex" alignItems="flex-end">
                <StyledBox>
                  <TextInput
                    variant="outlined"
                    resettable
                    type="number"
                    helperText={false}
                    InputProps={{
                      value: submissionId,
                    }}
                    source="SubmissionId"
                    label="Submission Id"
                    onChange={handleSubmissionIdChange}
                  />
                </StyledBox>
                <Box display="flex" alignItems="flex-end" mb={3} mr={1}>
                  <FormLabel>Or</FormLabel>
                </Box>
                <StyledBox>
                  <Box component="span" mr={2}>
                    <TextInput
                      variant="outlined"
                      disabled={submissionIdHasValue}
                      resettable
                      helperText={false}
                      InputProps={{
                        value: formNum,
                        disabled: Boolean(submissionIdHasValue),
                      }}
                      source="FormNum"
                      label="Form Number"
                      onChange={handleFormNumChange}
                    />
                  </Box>
                  <Box component="span" width={250}>
                    <SelectFinanceCoInput
                      disabled={submissionIdHasValue}
                      InputProps={{
                        onChange: (e) => {
                          setFinanceCo(e.target.value)
                          setFilters({})
                        }
                      }}
                      SelectProps={{
                        value: financeCo
                      }}
                    />
                  </Box>
                </StyledBox>
                <Box component="span" mr={2} mb={2.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<SearchIcon />}
                    disabled={searchBtnDisabled}>
                    Search
                </Button>
                </Box>
                <Box component="span" mr={2} mb={2.5}>
                  <Button
                    variant="contained"
                    color="primary"
                    disabled={saveBtnDisabled}
                    startIcon={<AddIcon />}
                    component={AddStateLink}>
                    Add States
                </Button>
                </Box>
              </Box>
            </CardContentInner>
          </form>
        )}
      </Form>
    </div>
  );
};

export default Filter
