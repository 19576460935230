import React from 'react';
import { Box } from '@material-ui/core';

export default class SanitizedBox extends React.PureComponent {
  render() {
    return (
      <Box {...this.props} />
    )
  }
}
