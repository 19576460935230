import React from 'react';
import { List, Datagrid, TextField } from 'react-admin';

const FormList = props => {
    return (
        <List {...props}>
            <Datagrid rowClick="edit">
                <TextField source="GroupName" />
                <TextField source="Description" />                
            </Datagrid>
        </List>
    )
};

export default FormList;