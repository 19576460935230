import React, { Fragment, useRef, useState } from 'react';
import { List, Datagrid, TextField, NumberField, DateField, Button } from 'react-admin';
import Filter from './filter';
import Pagination from '../common/Pagination'
import { useReactToPrint } from 'react-to-print';
import { Form } from 'react-final-form';
import PrintIcon from '@material-ui/icons/Print';
import PrintCalculationClassBased from '../calculator/printCalculationClassBased';

const PrintButton = (props) => {
  const { printButtonClick, record, ...rest } = props;
  const handleClick = () => {
    printButtonClick(record);
  }
  return <Button onClick={handleClick} {...rest} />
}

const CalculationHistoryList = props => {
  const componentRef = useRef();
  const [recordToPrint, setRecordToPrint] = useState(null);

  const reactPrintFunction = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    reactPrintFunction();
    setRecordToPrint(null);
  }

  const handleClose = () => {
    setRecordToPrint(null);
  };

  const printButtonClick = (record) => {
    setRecordToPrint(record);
  }

  return (
    <Fragment>
      <List filters={<Filter />} pagination={<Pagination />} exporter={false} actions={false} bulkActionButtons={false} {...props}>
        <Datagrid rowClick="edit">
          <DateField source="purchaseDate" label="Cancelation Date" />
          <TextField source="formNum" label="Form Number" />
          <TextField source="accountNum" label="Account Number" />
          <NumberField source="purchaseAmt" label="GAP Waiver Purchase Price" options={{style: 'currency', currency: 'USD'}} />
          <TextField source="purchaseState" label="State Purchased" />
          <TextField source="contractMonths" label="Length of RISC" />
          <NumberField source="interestRate" label="Interest Rate" options={{style: 'percent', maximumFractionDigits: 2}} />
          <DateField source="contractEndDate" label="Date Cancelled or Termination of RISC" />
          <NumberField source="baseRefundAmt" label="Refund Amount" options={{style: 'currency', currency: 'USD'}} />
          <NumberField source="cancelFeeAmt" label="Cancellation Fee" options={{style: 'currency', currency: 'USD'}} />
          <NumberField source="interestRefundAmt" label="Interest Refund Amount" options={{style: 'currency', currency: 'USD'}} />
          <NumberField source="totalRefundAmt" label="Total Refund Due" options={{style: 'currency', currency: 'USD'}} />
          <TextField source="user" label="User" />
          <TextField source="financeCo" label="Finance Co" />
          <PrintButton
            variant='outlined'
            color='primary'
            startIcon={<PrintIcon />}
            label='Print'
            printButtonClick={printButtonClick} />
        </Datagrid>
      </List>
      <PrintCalculationClassBased HandlePrint={handlePrint} ref={componentRef} HandleClose={handleClose} values={recordToPrint} open={recordToPrint !== null} />
    </Fragment>
  )
};

const CalculationHistoryForm = (props) => {
  const onSubmit = (e) => {

  }

  return (
    <Form onSubmit={onSubmit} render={props => <CalculationHistoryList {...props} />} {...props} />
  );
}

export default CalculationHistoryForm;
