// noinspection DuplicatedCode

import React, { useRef } from 'react';
import {
  CardContentInner,
  FormWithRedirect,
  NumberInput,
  required,
  SaveButton,
  SelectInput,
  TextInput,
  Toolbar,
  useDataProvider,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Box, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import States from '../common/States';
import FeeCalcMethods from '../common/FeeCalcMethods';
import RefundCalcMethods from '../common/RefundCalcMethods';
import Booleans from '../common/Booleans';
import resources from '../../dataProvider/resources';
import LabeledOutline from '../common/LabeledOutline';
import { Field, useField } from 'react-final-form';
import { connect } from 'react-redux';
import { statesAddedAction } from './statesAddedAction';
import StatesSaveButton from './StatesSaveButton';

export const styles = {
  label: { fontSize: 12, textAlign: 'left', maxWidth: '95%' },
  input: { minWidth: '0', width: '100%' },
  hiddenLabeledOutline: {
    content: {
      padding: "18.5px 0px"
    },
    border: {
      border: 'none'
    },
  }
};
const useStyles = makeStyles(styles);

const FormManagementForm = props => {
  const classes = useStyles();
  const hiddenLabeledOutline = makeStyles(styles.hiddenLabeledOutline)();
  const {isEditing} = props;

  const SanitizedBox = ({ basePath, handleSubmit, handleSubmitWithRedirect, onSave, submitOnEnter, ...props }) => {
    return (
      <Box {...props} />
    );
  };

  const SelectInputStyled = props => {
    return (
      <SelectInput
        variant="outlined"
        fullWidth={true}
        className={classes.input}
        InputLabelProps={{
          classes: {
            root: classes.label
          },
          shrink: true
        }}
        validate={[required()]}
        {...props} />
    );
  };

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const AutocompleteInputStyledMultipleStates = ({input}) => {
    return (
      <Autocomplete
        multiple
        disableCloseOnSelect
        options={States}
        getOptionLabel={(option) => option.name}
        onChange={(event, newValue) => input.onChange(newValue)}
        onBlur={(event) => input.onBlur(event)}
        renderOption={(option, { selected }) => (
          <React.Fragment>
            <Checkbox
              icon={icon}
              checkedIcon={checkedIcon}
              style={{ marginRight: 8 }}
              checked={selected}
            />
            {option.name}
          </React.Fragment>
        )}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="States"
            placeholder="States"
            className={classes.input}
            InputLabelProps={{
              classes: {
                root: classes.label
              },
              shrink: true
            }}
          />
        )}
      />
    );
  };

  const NumberInputStyled = props => {
    return (
      <NumberInput
        variant="outlined"
        InputLabelProps={{
          classes: {
            root: classes.label
          },
          shrink: true
        }}
        min={0}
        validate={[required()]}
        {...props} />
    );
  };

  const TextInputStyled = props => {
    return (
      <TextInput
        variant="outlined"
        InputLabelProps={{
          classes: {
            root: classes.label
          },
          shrink: true
        }}
        {...props} />
    );
  };

  const formNum = props.location.state?.formNum;
  const financeCo = props.location.state?.financeCo;
  const FormListLink = React.forwardRef((props, ref) => {
    return (
      <Link
        ref={ref}
        to={{
          pathname: "/forms",
        }}
        {...props} />
    )
  });

  const Error = ({ name }) => {
    const {
      meta: { touched, error }
    } = useField(name, { subscription: { touched: true, error: true } });
    return touched && error ? <span>{error}</span> : null;
  };

  const dataProvider = useDataProvider();
  const validateForm = async (values) => {
    if (isEditing)
      return {};

    let errors = {};
    let hasErrors = false;

    if ((values.formNum ?? "") === "")
    {
      hasErrors = true;
      errors.formNum = 'Form number is required';
    }

    if ((values.financeCo ?? "") === "") {
      hasErrors = true;
      errors.financeCo = 'Finance Company is required';
    }

    if ((values.states?.length ?? 0) === 0) {
      hasErrors = true;
      errors.states = 'At least one state is required';
    }

    if ((values.submissionId ?? 0) === 0) {
      hasErrors = true;
      errors.submissionId = 'The submission id is required';
    }

    if ((values.frpDays ?? 0) === 0) {
      hasErrors = true;
      errors.frpDays = 'The submission id is required';
    }

    if ((values.duringFrp ?? {}) === {}) {
      hasErrors = true;
      errors.duringFrp.root = 'During FRP is required';
    }
    else
    {
      errors.duringFrp = {};
      if ((values.duringFrp?.cancelFeeCalc ?? "") === "") {
        hasErrors = true;
        errors.duringFrp.cancelFeeCalc = 'The cancel fee calculation is required';
      }
      if ((values.duringFrp?.cancelFeeAmt ?? 0) === 0) {
        hasErrors = true;
        errors.duringFrp.cancelFeeAmt = 'The cancel fee amount is required';
      }
      if ((values.duringFrp?.finChargeRefund ?? 0) === 0) {
        hasErrors = true;
        errors.duringFrp.finChargeRefund = 'The finance charge refund is required';
      }
    }

    if ((values.afterFrp ?? {}) === {}) {
      hasErrors = true;
      errors.afterFrp.root = 'After FRP is required';
    }
    else
    {
      errors.afterFrp = {};
      if ((values.afterFrp?.refundCalc ?? "") === "") {
        hasErrors = true;
        errors.afterFrp.refundCalc = 'The refund calculation is required';
      }
      if ((values.afterFrp?.cancelFeeCalc ?? "") === "") {
        hasErrors = true;
        errors.afterFrp.cancelFeeCalc = 'The cancel fee calculation is required';
      }
      if ((values.afterFrp?.cancelFeeAmt ?? 0) === 0) {
        hasErrors = true;
        errors.afterFrp.cancelFeeAmt = 'The cancel fee amount is required';
      }
      if ((values.afterFrp?.finChargeRefund ?? 0) === 0) {
        hasErrors = true;
        errors.afterFrp.finChargeRefund = 'The cancel fee amount is required';
      }
    }

    if (hasErrors)
      return errors;

    let errs = await dataProvider.validateAsync(`${resources.forms}/validation/bulk-insert`, { data: values });
    let isValid = true;

    errs.data.every(it => {
      if (!it.isValid)
      {
        isValid = false;
        return false;
      }

      return true;
    });

    if (isValid === true)
      return {};

    errs.data.forEach(it => {
      errors = Object.assign(errors, it.errors);
    });

    let states = errs.data.map(it => it.errors.state ?? "");
    errors.states = states.join(" ");


    return errors;
  };

  return (
    <FormWithRedirect
      validate={validateForm}
      {...props}
      render={ formProps => (
        <form>
          <CardContentInner>
            <Grid container spacing={1} >
              <Grid item xs={1}>
                <TextInputStyled
                  source="formNum"
                  label="Form Number"
                  disabled
                  defaultValue={formNum}
                />
              </Grid>
              <Grid item xs={11}>
                <TextInputStyled
                  source="financeCo"
                  label="Finance Company"
                  disabled
                  defaultValue={financeCo}
                />
              </Grid>
              <Grid item xs={12}>
                {isEditing ?
                  <SelectInputStyled
                    disabled
                    source="state"
                    choices={States}
                  /> :
                  <Field name="states" component={AutocompleteInputStyledMultipleStates} />
                }
                <Error name={isEditing ? "state" : "states"} />
              </Grid>
              <Grid item xs={3} >
                <LabeledOutline classes={hiddenLabeledOutline}>
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <NumberInputStyled
                        source="submissionId"
                        label="Submission ID"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <NumberInputStyled
                        source="frpDays"
                        label="FRP (Days)"
                      />
                    </Grid>
                  </Grid>
                </LabeledOutline>
              </Grid>
              <Grid item xs={4}>
                <LabeledOutline label="FRP">
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <SelectInputStyled
                        source="duringFrp.cancelFeeCalc"
                        label='Cancel Fee Calc Method'
                        allowEmpty
                        choices={FeeCalcMethods}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <NumberInputStyled
                        source="duringFrp.cancelFeeAmt"
                        label='Cancel Fee Amount'
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <SelectInputStyled
                        source="duringFrp.finChargeRefund"
                        label='Finance Charge Refund Required'
                        choices={Booleans}
                      />
                    </Grid>
                  </Grid>
                </LabeledOutline>
              </Grid>
              <Grid item xs={5}>
                <LabeledOutline label="Post FRP">
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <SelectInputStyled
                        source="afterFrp.refundCalc"
                        label='Refund Calc Method'
                        allowEmpty
                        choices={RefundCalcMethods}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SelectInputStyled
                        source="afterFrp.cancelFeeCalc"
                        label='Cancel Fee Calc Method'
                        allowEmpty
                        choices={FeeCalcMethods}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <NumberInputStyled
                        source="afterFrp.cancelFeeAmt"
                        label='Cancel Fee Amount'
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <SelectInputStyled
                        source="afterFrp.finChargeRefund"
                        label='Finance Charge Refund Required'
                        choices={Booleans}
                      />
                    </Grid>
                  </Grid>
                </LabeledOutline>
              </Grid>
            </Grid>
          </CardContentInner>
          <Toolbar>
            <SanitizedBox component="span" mr={2} mb={1.5}>
              {
                isEditing ?
                  <SaveButton
                  label={isEditing ? "Update" : "Save"}
                  disabled={formProps.invalid}
                  variant="contained"
                  color="primary"
                  saving={formProps.saving}
                  handleSubmitWithRedirect={formProps.handleSubmitWithRedirect}
                /> :
                  <StatesSaveButton
                  label="Save"
                  disabled={formProps.invalid || formProps.validating || formProps.pristine }
                  variant="contained"
                  color="primary"
                  form={formProps.form}
                  >
                    Save
                  </StatesSaveButton>
                }
            </SanitizedBox>
            <SanitizedBox component="span" mr={2} mb={1.5}>
              <Button
                variant="contained"
                color="primary"
                onClick={formProps.onCancel}
                label="Cancel"
                startIcon={<CancelIcon />}
                component={FormListLink}>
                Cancel
              </Button>
            </SanitizedBox>
          </Toolbar>
        </form>
      )}
    />
  )
};

export default connect(null, {statesAdded: statesAddedAction})(FormManagementForm)
