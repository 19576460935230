import { CALCULATION_ADDED } from '../actions.js';

const initialState = [{
  id: 1,
  calculation_date: '02/12/2020',
  form_number: 111111,
  account_number: 111111,
  gap_waiver_purchase_price: 765,
  state_purchased: 'Alabama',
  length_of_risc: 60,
  interest_rate: 4.25,
  date_canceled: '02/01/2021',
  refund_amount: 345,
  cancelation_fee: 50,
  interest_refund_amount: 25.1,
  total_refund_due: 800,
  user: 'martinezj',
  finance_company: 'TFS'
}, {
  id: 2,
  calculation_date: '02/12/2020',
  form_number: 111112,
  account_number: 111112,
  gap_waiver_purchase_price: 765,
  state_purchased: 'Alabama',
  length_of_risc: 60,
  interest_rate: 4.25,
  date_canceled: '02/01/2021',
  refund_amount: 345,
  cancelation_fee: 50,
  interest_refund_amount: 25.1,
  total_refund_due: 800,
  user: 'martinezj',
  finance_company: 'TFS'
}, {
  id: 3,
  calculation_date: '02/12/2020',
  form_number: 111113,
  account_number: 111113,
  gap_waiver_purchase_price: 765,
  state_purchased: 'Alabama',
  length_of_risc: 60,
  interest_rate: 4.25,
  date_canceled: '02/01/2021',
  refund_amount: 345,
  cancelation_fee: 50,
  interest_refund_amount: 25.1,
  total_refund_due: 800,
  user: 'martinezj',
  finance_company: 'TFS'
}];

const calculationHistoryReducer = (state = initialState, action) => {
  if (action.type === CALCULATION_ADDED) {
    return state.slice().push(action.payload);
  }
  return state;
}

export default calculationHistoryReducer;
