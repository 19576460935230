import calculatorIcon from './calculatorIcon';
import CalculatorForm from './calculator';
import resources from '../../dataProvider/resources';

const calculator = {
  icon: calculatorIcon,
  resource: resources.calculator,
  route: `/${resources.calculator}`,
  Form: CalculatorForm
};

export default calculator