import resources from '../../dataProvider/resources'
import { SelectInput, Labeled, LinearProgress, useQuery } from 'react-admin';

const SelectFinanceCoInput = props => {
  const { ...financeCos } = useQuery({ type: 'getList', resource: resources.financeCos })
  const label = "Finance Company"

  if (!financeCos.loading) {

    var initialValue = ""
    var allowEmpty = true;
    if (financeCos.total === 1) {
      initialValue = financeCos.data[0].id;
      allowEmpty = false;
    }    

    return (
      <SelectInput
        allowEmpty={allowEmpty}
        variant="outlined"
        source="financeCo"
        choices={financeCos?.data}
        initialValue={initialValue}
        helperText={false}
        optionText="financeCompany"
        fullWidth
        label={label}
        {...props}
      />

    );
  }
  else
    return (
      <Labeled
        id={props.id}
        label={label}
        source={props.source}
        resource={props.resource}
        className={props.className}
        isRequired={props.isRequired}
        meta={props.meta}
        input={props.input}
      >
        <LinearProgress />
      </Labeled>
    );
};

export default SelectFinanceCoInput;
