import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id:null,
  firstName:null,
  lastName:null,
  email:null,
  isAdmin: false,
  isDataEntry: false,
  financeCompanies: [],
  userNotFound: false,
  token: null
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, action) => {
      Object.keys(action.payload).forEach(function(key) {
        state[key] = action.payload[key]
      })
    },
    setUserToken: (state, action) => {
      state.token = action.payload
    },
    setUserNotFound: (state, action) => {
      state.userNotFound = action.payload
    },
    resetUser: (state, action) => {
      state = initialState
    },
  }
});
export const {
  setUser,
  setUserNotFound,
  resetUser,
  setUserToken
} = userSlice.actions;

export default userSlice.reducer;
