import React from 'react'
import {
  useNotify,
  useRedirect,
  Edit
} from 'react-admin';
import FormManagementForm from './formManagementForm'

const FormEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Entry Updated");
    redirect('/forms');
  };

  return (
    <Edit onSuccess={onSuccess} mutationMode="pessimistic" {...props}>
      <FormManagementForm isEditing={true} {...props} />
    </Edit>
  )
};

export default FormEdit
