import React from 'react';
import { colors } from '../app/theme';
import { forwardRef } from 'react';
import { AppBar, UserMenu, MenuItemLink } from 'react-admin';
import PersonIcon from '@material-ui/icons/Person';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Typography, Toolbar, Grid } from '@material-ui/core'
import { useSelector } from 'react-redux';

import FAIRR_1 from './../assests/FAIRR_1.png';

const useStyles = makeStyles({
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    spacer: {
        flex: 1,
    },
});

const ConfigurationMenu = forwardRef((props, ref) => {
    return (
        <MenuItemLink
            ref={ref}
            to="/Profile"
            primaryText="Profile"
            leftIcon={<PersonIcon />}
            onClick={props.onClick}
            sidebarIsOpen
        />
    );
});

const CustomUserMenu = (props) => {
    const user = useSelector(state => state.user);    
    return (
    <React.Fragment>
        <Typography noWrap>
            Hello, {user.idTokenClaims.given_name}
        </Typography>
        <UserMenu {...props}>
            <ConfigurationMenu />
        </UserMenu>
    </React.Fragment>
)};

const Container = styled.div`
  flexgrow: 2;
`;

const StyledAppBar = styled(AppBar)`
  background-color: ${colors.grey};
  color: ${colors.white};
  width: 100%;
`;

const StyledLogContainer = styled.div`
  width: 100%;
  background-color: transparent;
  display: inline-flex;
  justify-content: flex-start;
  color: ${colors.darkGrey};
`;

const LogoImg = styled.img`
  width: 100%;
`
const CustomAppBar = (props) => {
    const classes = useStyles();
    return (
        <Container>
            <StyledAppBar {...props} elevation={1} userMenu={<CustomUserMenu />}>
                <Typography
                    variant="h6"
                    color="inherit"
                    className={classes.title}
                >
                    <Toolbar>
                        <StyledLogContainer alignItems="center">
                            <Grid container spacing={4} alignItems="center">
                                <Grid item xs={2}>
                                    <LogoImg src={FAIRR_1} />
                                </Grid>
                            </Grid>
                        </StyledLogContainer>
                    </Toolbar>
                </Typography>

                <span className={classes.spacer} />
            </StyledAppBar>
        </Container>
    );
};

export default CustomAppBar;
