import { Fragment, Component } from "react";
import { List, TextField, BooleanField, NumberField, EditButton } from 'react-admin'
import { Route, Switch } from 'react-router';
import { Dialog, DialogTitle } from '@material-ui/core';

import Datagrid from '../common/Datagrid'
import Filter from './filter'
import Pagination from '../common/Pagination'
import FormCreate from './formCreate';
import FormEdit from './formEdit';
import UpdateActiveCheckbox from './updateActiveCheckbox';

class FormList extends Component {
  render() {
    const props = this.props;
    return (
      <Fragment>
        <List {...props} filters={<Filter />} empty={false} exporter={false} pagination={<Pagination />} actions={false} bulkActionButtons={false}>
          <Datagrid displayHeadersWhenEmpty={true}>
            {props.permissions?.includes('Forms.Update') ? <EditButton label=""/> : null}
            <TextField source="state" label="States" />
            <TextField source="submissionId" label="Submission ID" />
            <NumberField source="frpDays" label="Full Refund Period (Days)" />
            <TextField source="duringFrp.cancelFeeCalc" label="During FRP Cancel Fee Calculation Method" />
            <NumberField source="duringFrp.cancelFeeAmt" label="During FRP Cancel Fee Amount" options={{ style: 'currency', currency: 'USD' }} />
            <BooleanField source="duringFrp.finChargeRefund" label="During FRP Finance Charge Refund Required" />
            <TextField source="afterFrp.refundCalc" label="Post FRP Refund Calculation Method" />
            <TextField source="afterFrp.cancelFeeCalc" label="Post FRP Cancel Fee Calculation Method" />
            <NumberField source="afterFrp.cancelFeeAmt" label="Post FRP Cancel Fee Amount" options={{ style: 'currency', currency: 'USD' }} />
            <BooleanField source="afterFrp.finChargeRefund" label="Post FRP Finance Charge Refund Required" />
            {props.permissions?.includes('Forms.Update') ? <UpdateActiveCheckbox source="active" /> : <BooleanField source="active" />}
          </Datagrid>
        </List>
        <Switch>
          <Route path="/forms/create">
            <Dialog open={true} onClose={this.handleClose} maxWidth={false}>
              <DialogTitle onClose={this.handleClose}>
                Add States
              </DialogTitle>
              <FormCreate onCancel={this.handleClose} {...props} />
            </Dialog>
          </Route>
          <Route path="/forms/:id">
            {({ match }) => (
              <Dialog open={props.permissions?.includes('Forms.Update')} onClose={this.handleClose} maxWidth={false}>
                <DialogTitle onClose={this.handleClose}>
                  Edit State
                </DialogTitle>
                <FormEdit id={match.params.id} onCancel={this.handleClose} {...props} />
              </Dialog>
             )}
           </Route>
        </Switch>
      </Fragment>
    );
  }


  handleClose = () => {
  };
}

export default FormList;
