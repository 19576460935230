import { TableCell, TableRow } from '@material-ui/core';
import NumberFormat from 'react-number-format';
import React from 'react';

export default class CalcResultsTableRow extends React.PureComponent {
  render() {
    return (
      <TableRow>
        <TableCell className={this.props.className} align='right' component='th' scope='row'>{this.props.label}</TableCell>
        <TableCell className={this.props.className} align='right'>
          <NumberFormat
            value={this.props.value}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true} />
        </TableCell>
      </TableRow>
    )
  }
}
