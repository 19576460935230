import { Button } from '@material-ui/core';
import { connect } from 'react-redux'
import { statesAddedAction } from './statesAddedAction';
import { useRef } from 'react';

const StatesSaveButton = (props) => {

  let x = useRef();
  const onCreateSaveClickHandler = () => {
    const formValues = props.form.getState().values;
    props.statesAdded({data: formValues}, props.basePath);
  }

  return (
    <Button onClick={onCreateSaveClickHandler} {...props}>
      Save
    </Button>
  );
}

export default connect(null, {statesAdded: statesAddedAction})(StatesSaveButton);
