import * as React from 'react';
import { Layout } from 'react-admin';
import AppBar from './appBar';
import Menu from './menu';

const layout = (props) => {
    return (
        <Layout
            {...props}
            appBar={AppBar}
            menu={Menu}
        />
    );
};

export default layout;