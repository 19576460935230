import React from 'react';
import { useState, useEffect } from "react";
import { Form } from 'react-final-form';
import { Box, Button, FormLabel } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { TextInput, CardContentInner, useListContext } from 'react-admin';
import { Link as RouterLink } from 'react-router-dom';
import SelectFinanceCoInput from '../common/SelectFinanceCoInput'

const styles = {
  card: {
    boxShadow: 'none'
  }
};

const useStyles = makeStyles(styles);

const StyledBox = props => {
  return (
    <Box
      borderColor="text.secondary"
      border={1}
      align="center"
      padding={1}
      paddingLeft={2.5}
      paddingRight={2.5}
      borderRadius={8}
      display="flex"
      alignItems="flex-end"
      mr={1}
      {...props} />
  );
};

const TryParseInt = (str, defaultValue) => {
  var retValue = defaultValue;
  if (str?.length > 0) {
    if (!isNaN(str)) {
      retValue = parseInt(str);
    }
  }
  return retValue;
}

const Filter = () => {
  const classes = useStyles();

  const {
    filterValues,
    setFilters,
    data,
    ids
  } = useListContext();

  const onSubmit = () => {
    var filters = {
      FormNum: formNum,
      AccountNum: accountNum,
      FinanceCos: financeCo.length === 0 ? null : [financeCo]
    }
    setFilters(filters);
  };

  var financeCoInitialValue = ""

  const [formNum, setFormNum] = useState("");
  const [accountNum, setAccountNum] = useState("");
  const [financeCo, setFinanceCo] = useState(financeCoInitialValue);

  const handleFormNumChange = (e) => {
    const formNum = e.target?.value;
    setFormNum(formNum?.length > 0 ? formNum : "");
    setFilters({})
  }

  const handleAccountNumChange = (e) => {
    const accountNum = e.target?.value;
    setAccountNum(accountNum?.length > 0 ? accountNum : "");
    setFilters({})
  }

  /*useEffect(() => {
    setFormNum(data[ids[0]]?.formNum ?? "");
    setAccountNum(data[ids[0]]?.accountNum ?? "");
    setFinanceCo(data[ids[0]]?.financeCo ?? financeCoInitialValue);
    setSearchBtnDisabled(formNum.length === 0 || accountNum.length === 0 || financeCo.length === 0);
  }, [data, ids, financeCoInitialValue, formNum, accountNum, financeCo])*/

  return (
    <div>
      <Form onSubmit={onSubmit} initialValues={filterValues}>
        {props => (
          <form onSubmit={props.handleSubmit}>
            <CardContentInner className={classes.card}>
              <Box display="flex" alignItems="flex-end">
                <Box component="span" mr={2}>
                  <TextInput
                    variant="outlined"
                    resettable
                    helperText={false}
                    InputProps={{
                      value: formNum,
                    }}
                    source="FormNum"
                    label="Form Number"
                    onChange={handleFormNumChange}
                  />
                </Box>
                <Box component="span" mr={2}>
                  <TextInput
                    variant="outlined"
                    resettable
                    helperText={false}
                    InputProps={{
                      value: accountNum,
                    }}
                    source="AccountNum"
                    label="Account Number"
                    onChange={handleAccountNumChange}
                  />
                </Box>
                <Box component="span" width={250}>
                  <SelectFinanceCoInput
                    InputProps={{
                      onChange: (e) => {
                        setFinanceCo(e.target.value)
                        setFilters({})
                      }
                    }}
                    SelectProps={{
                      value: financeCo
                    }}
                  />
                </Box>
                <Box component="span" ml={2} mb={1.3}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<SearchIcon />}
                    disabled={formNum.length === 0 && accountNum.length === 0 && financeCo.length === 0}>
                    Search
                </Button>
                </Box>
              </Box>
            </CardContentInner>
          </form>
        )}
      </Form>
    </div>
  );
};

export default Filter
