import authentication from './b2c';

const authProvider = {
  login: params => Promise.resolve(),
  checkError: error => Promise.resolve(),
  checkAuth: params => Promise.resolve(),
  logout: () => authentication.signOut(),
  getIdentity: () => Promise.resolve(),
  getPermissions: () => {
    const claims = authentication.getAccount().idTokenClaims
    return claims ? Promise.resolve(claims.appRoles) : Promise.reject();
  }
};

export default authProvider;