import {useState} from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import {useDataProvider, useNotify} from "react-admin";
import resources from '../../dataProvider/resources'

function UpdateActiveCheckbox({record}) {
  const [checked, setChecked] = useState(record.active);
  const [showDialog, setShowDialog] = useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked)
    setShowDialog(true);
  };

  const handleClose = () => {
    setShowDialog(false);
  };

  const ConfirmationDialog = () => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    if(checked) {
      var task = 'Reactivate'
      var message = 'Active entries can'
    }
    else{
      task = 'Deactivate'
      message = 'Deactivated entries can not'
    }

    const handleConfirm = async change => {
      change = {
        active: checked
      }
      dataProvider.updateActive(resources.forms, {id: record.id, data: change })
      .then(({data}) => {
        if(data.active === checked)
          notify('Entry updated')
      })
      .catch(error => {
        if(error.body?.errors)
          return error.body.errors
        else
          notify(error.message, 'warning')
      })
      handleClose()
    }

    const handleCancel = () => {
      setChecked(!checked)
      handleClose()
    }

    return (
        <Dialog open={showDialog} onClose={handleClose}>
          <DialogTitle>{`${task} this entry?`}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`${message} be used in refund calculations.`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirm} color="primary" autoFocus>
              {task}
            </Button>
          </DialogActions>
        </Dialog>
    )
  }

  return (
    <div>
    <Checkbox
      color="primary"
      checked={checked}
      onClick={handleChange}/>
      <ConfirmationDialog/>
      </div>
  )
};

UpdateActiveCheckbox.propTypes = {
    record: PropTypes.object,
};

export default UpdateActiveCheckbox;
