import { Form, useForm, useFormState } from 'react-final-form';
import {
  Button,
  Card,
  CardHeader,
  Grid,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import {
  DateInput,
  NumberInput,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  useNotify,
} from 'react-admin';
import NumberFormat from 'react-number-format';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import PrintIcon from '@material-ui/icons/Print';
import CalculatorIcon from './calculatorIcon';
import SelectFinanceCoInput from '../common/SelectFinanceCoInput';
import SelectFormNumAutocompleteInput from '../common/AutocompleteFormNumInput';
import LabeledOutline from '../common/LabeledOutline';
import States from '../common/States';
import resources from '../../dataProvider/resources';
import React, { Fragment, useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintCalculationClassBased from './printCalculationClassBased';
import { connect } from 'react-redux';

export const styles = {
  grid: { minWidth: '340px' },
  calculateButtonGrid: { minWidth: '150px' },
  tableCell: { border: 'none' },
  tableCellTotal: { borderBottom: 'none', borderTop: 'solid 1px' },
};

const useStyles = makeStyles(styles);

const SelectInputStyled = props => {
  return (
    <SelectInput
      variant='outlined'
      InputLabelProps={{
        shrink: true,
      }}
      validate={[required()]}
      fullWidth
      helperText={false}
      {...props} />
  );
};

const NumberInputStyled = props => {
  return (
    <NumberInput
      variant='outlined'
      InputLabelProps={{
        shrink: true,
      }}
      min={0}
      fullWidth
      helperText={false}
      {...props} />
  );
};

const TextInputStyled = props => {
  return (
    <TextInput
      variant='outlined'
      InputLabelProps={{
        shrink: true,
      }}
      fullWidth
      helperText={false}
      {...props} />
  );
};

const DateInputStyled = props => {
  return (
    <DateInput
      variant='outlined'
      InputLabelProps={{
        shrink: true,
      }}
      validate={[required()]}
      fullWidth
      helperText={false}
      {...props} />
  );
};

const Calculator = (props) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();

  const onSubmit = async values => {
    return dataProvider.validate(`${resources.forms}/calculation`, { data: values })
      .then(({ data }) => {
        values.calcResults = data;
      })
      .catch(error => {
        if (error.body?.errors)
          return error.body.errors;
        else
          notify(error.message, 'warning');
      });
  };

  return (
    <Form onSubmit={onSubmit}
          {...props}
          render={props => <CalculatorForm {...props} />}
    />
  );
};

const CalculatorForm = (props) => {
  const classes = useStyles();
  const { values, dirtySinceLastSubmit } = useFormState();
  const useform = useForm();
  const [ printDialogOpen, setPrintDialogOpen ] = useState(false);

  const interestRateValidation = values.interestRateRequired ? [required()] : null;

  if (dirtySinceLastSubmit && values.calcResults)
    for (const prop of Object.getOwnPropertyNames(values.calcResults)) {
      values.calcResults[prop] = '';
    }

  const handleFormNumChange = (e) => {
    useform.resetFieldState('interestRate');
  };

  const componentRef = useRef();
  const reactPrintFunction = useReactToPrint({
    content: () => componentRef.current,
  });

  const handlePrint = () => {
    reactPrintFunction();
    setPrintDialogOpen(false);
  }

  const CalcResultsTableRow = ({ label, value, className = classes.tableCell, ...props }) => {
    return (
      <TableRow>
        <TableCell className={className} align='right' component='th' scope='row'>{label}</TableCell>
        <TableCell className={className} align='right'>
          <NumberFormat
            value={value}
            displayType={'text'}
            thousandSeparator={true}
            prefix={'$'}
            decimalScale={2}
            fixedDecimalScale={true} />
        </TableCell>
      </TableRow>
    );
  };

  const printButtonClick = () => {
    setPrintDialogOpen(true);
  }

  const handleClose = () => {
    setPrintDialogOpen(false);
  }

  return (
    <Fragment>
      <form onSubmit={props.handleSubmit}>
        <Card>
          <CardHeader variant='contained' title={
            <>
              <Typography variant='h5' align='left' color='primary'>
                GAP Waiver Refund Calculator
              </Typography>
            </>
          }>
          </CardHeader>
          <Grid container spacing={5}>
            <Grid item xs={1} className={classes.grid}>
              <SelectFinanceCoInput
                InputLabelProps={{
                  shrink: true,
                }}
                validate={[required()]}
              />
              <SelectInputStyled
                source='purchaseState'
                label='State Purchased'
                choices={States}
              />
              <SelectFormNumAutocompleteInput
                financeCo={values.financeCo}
                purchaseState={values.purchaseState}
                label='Form Number'
                InputLabelProps={{
                  shrink: true,
                }}
                suggestionLimit={5}
                validate={[required()]}
                onChange={handleFormNumChange}
              />
              <TextInputStyled
                source='accountNum'
                label='Account Number'
              />
              <NumberInputStyled
                source='purchaseAmt'
                label='GAP Waiver Purchase Price'
                InputProps={{
                  startAdornment: (<InputAdornment position='start'>$</InputAdornment>),
                }}
                validate={[required()]}
              />
              <DateInputStyled
                source='purchaseDate'
                label='Date Purchased'
              />
              <NumberInputStyled
                source='contractMonths'
                label='Length of RISC (months)'
                validate={[required()]}
              />
              <NumberInputStyled
                source='interestRate'
                label='Interest Rate'
                InputProps={{
                  endAdornment: (<InputAdornment position='end'>%</InputAdornment>),
                }}
                validate={interestRateValidation}
                key={values.interestRateRequired ? 1 : 0}
              />
              <DateInputStyled
                source='contractEndDate'
                label='Date Cancelled or Termination of RISC'
              />
            </Grid>
            <Grid item xs={4} className={classes.grid}>
              <LabeledOutline label='Results'>
                <Table size='small'>
                  <TableBody>
                    <CalcResultsTableRow label='Refund Amount:' value={values.calcResults?.baseRefundAmt} />
                    <CalcResultsTableRow label='Cancellation Fee:' value={values.calcResults?.cancelFeeAmt} />
                    <CalcResultsTableRow label='Interest Refund Amount:'
                                         value={values.calcResults?.interestRefundAmt} />
                    <CalcResultsTableRow label='Total Refund Due:' value={values.calcResults?.totalRefundAmt}
                                         className={classes.tableCellTotal} />
                  </TableBody>
                </Table>
                <Button
                  variant='contained'
                  color='primary'
                  startIcon={<PrintIcon />}
                  onClick={printButtonClick}
                >
                  Print
                </Button>
              </LabeledOutline>
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item xs={1} className={classes.calculateButtonGrid}>
              <Button
                variant='contained'
                color='primary'
                startIcon={<CalculatorIcon />}
                type='submit'
                disabled={props.submitting}
              >
                Calculate
              </Button>
            </Grid>
            <Grid item xs={1}>
              <Button
                variant='contained'
                color='primary'
                onClick={() => {
                  props.form.restart();
                }}
                startIcon={<ClearIcon />}
              >
                Clear
              </Button>
            </Grid>
          </Grid>
        </Card>
      </form>
      <PrintCalculationClassBased HandlePrint={handlePrint} ref={componentRef} HandleClose={handleClose} values={useform.getState().values} open={printDialogOpen} />
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    username: state.user.name
  };
}

export default connect(mapStateToProps)(Calculator);
