import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import authentication from '../auth/b2c';
import { apiConfig } from '../auth/apiConfig';
import resources from './resources';

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = await authentication.getAccessToken();
  options.headers.set('Authorization', `Bearer ${token}`);
  return fetchUtils.fetchJson(url, options);
};

const jsonStringify = (obj) => JSON.stringify(obj, (key, value) => {
  if (value !== null) return value
})

const apiUrl = apiConfig.apiUrl;

const DataProvider = {
  getList: async (resource, params) => {
    const filter = jsonStringify(params?.filter)
    if (resource === resources.forms && filter === "{}") {
      return Promise.resolve({
        data: [],
        total: null
      })
    } else if (resource === resources.calculationHistory) {
      return Promise.resolve({
        data: [{
            id: 1,
            purchaseDate: '02/12/2020',
            formNum: 111111,
            accountNum: 111111,
            purchaseAmt: 765,
            purchaseState: 'Alabama',
            contractMonths: 60,
            interestRate: .0425,
            contractEndDate: '02/01/2021',
            baseRefundAmt: 345,
            cancelFeeAmt: 50,
            interestRefundAmt: 25.1,
            totalRefundAmt: 800,
            user: 'martinezj',
            financeCo: 'TFS',
            calcResults: {
              baseRefundAmt: 50,
              cancelFeeAmt: 50,
              interestRefundAmt: 50,
              totalRefundAmt: 150
            }
          }, {
            id: 2,
            purchaseDate: '02/12/2020',
            formNum: 111112,
            accountNum: 111112,
            purchaseAmt: 765,
            purchaseState: 'Alabama',
            contractMonths: 60,
            interestRate: .0425,
            contractEndDate: '02/01/2021',
            baseRefundAmt: 345,
            cancelFeeAmt: 50,
            interestRefundAmt: 25.1,
            totalRefundAmt: 800,
            user: 'martinezj',
            financeCo: 'TFS',
            calcResults: {
              baseRefundAmt: 50,
              cancelFeeAmt: 50,
              interestRefundAmt: 50,
              totalRefundAmt: 150
            }
          }, {
            id: 3,
            purchaseDate: '02/12/2020',
            formNum: 111113,
            accountNum: 111113,
            purchaseAmt: 765,
            purchaseState: 'Alabama',
            contractMonths: 60,
            interestRate: .0425,
            contractEndDate: '02/01/2021',
            baseRefundAmt: 345,
            cancelFeeAmt: 50,
            interestRefundAmt: 25.1,
            totalRefundAmt: 800,
            user: 'martinezj',
            financeCo: 'TFS',
            calcResults: {
              baseRefundAmt: 50,
              cancelFeeAmt: 50,
              interestRefundAmt: 50,
              totalRefundAmt: 150
            }
          }],
        total: 3
      });
    }

    // const { field, order } = params.sort;
    const query = {
      // sort: JSON.stringify([field, order]),
      Filter: filter,
      Page: params?.pagination?.page,
      RowsPerPage: params?.pagination?.perPage,
    };
    // authentication.callApi(apiConfig.formsManagementApi);

    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    const { headers, json } = await httpClient(url);
    if (json.data === null) {
      return {
        data: [],
        total: 0
      };
    }
    return {
      data: json.data,
      total: json.itemCount
    };
  },

  getOne: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
      data: json,
    })),

  getMany: (resource, params) => {
    // const query = {
    //   id: params.ids,
    // };
    const url = `${apiUrl}/${resource}`;
    return httpClient(url).then(({ headers, json }) => {
      return {
        data: json.data,
        total: json.itemCount
      }
    });
  },

  update: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  updateActive: (resource, params) =>
    httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'PATCH',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json })),

  create: (resource, params) => {
    return httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      return ({
        data: json[0],
      });
    });
  },

  validate: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    })),

  validateAsync: async (resource, params) =>
  {
    const {headers, json } = await httpClient(`${apiUrl}/${resource}`,
      {
        method: 'POST',
        body: JSON.stringify(params.data)
      } );

    return {data: json };

  },

  calculate: (resource, params) =>
    httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: json,
    })),
};

export default DataProvider;
