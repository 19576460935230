import { Button, Dialog, DialogTitle, Grid, Table, TableBody } from '@material-ui/core';
import React from 'react';
import { CardContentInner, Toolbar } from 'react-admin';
import LabeledOutline from '../common/LabeledOutline';
import { withStyles } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';

import SanitizedBox from './common/SanitizedBox';
import TextTableRow from './common/TextTableRow';
import CalcResultsTableRow from './common/CalcResultsTableRow';
import PercentTableRow from './common/percentTableRow';

const styles = theme => ({
  grid: { minWidth: '340px' },
  calculateButtonGrid: { minWidth: '150px' },
  tableCell: { border: 'none' },
  tableCellTotal: { borderBottom: 'none', borderTop: 'solid 1px' },
});

class PrintCalculationClassBased extends React.PureComponent {
  render() {
    const {values, HandleClose, HandlePrint, classes, open } = this.props;
    //console.log(values);

    return (
      <Dialog open={open} onClose={HandleClose} maxWidth={false}>
        <DialogTitle onClose={HandleClose}>
          GAP Waiver Refund Calculation
        </DialogTitle>
        <CardContentInner>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              User:
            </Grid>
            <Grid item xs={6}>
              Marty Maron
            </Grid>
            <Grid item xs={6}>
              Calculation Date:
            </Grid>
            <Grid item xs={6}>
              {values?.calcResults?.calcDate ? values?.calcResults?.calcDate.substring(0, 10) : ''}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabeledOutline label='Inputs'>
                <Table size='small'>
                  <TableBody>
                    <TextTableRow label='Form Number:' value={values?.formNum} />
                    <TextTableRow label='State Purchased:' value={values?.purchaseState} />
                    <TextTableRow label='Finance Company:' value={values?.financeCo} />
                    <TextTableRow label='Account Number:' value={values?.accountNum} />
                    <CalcResultsTableRow label='GAP Waiver Purchase Price:' value={values?.purchaseAmt} />
                    <TextTableRow label='Date Purchased:' value={values?.purchaseDate} />
                    <TextTableRow label='Length of RISC (months):' value={values?.contractMonths} />
                    <PercentTableRow label='Interest Rate:' value={values?.interestRate} />
                    <TextTableRow label='Date Cancelled or Termination of RISC:' value={values?.contractEndDate} />
                  </TableBody>
                </Table>
              </LabeledOutline>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <LabeledOutline label='Results'>
                <Table size='small'>
                  <TableBody>
                    <CalcResultsTableRow label='Refund Amount:' value={values?.calcResults?.baseRefundAmt} />
                    <CalcResultsTableRow label='Cancellation Fee:' value={values?.calcResults?.cancelFeeAmt} />
                    <CalcResultsTableRow label='Interest Refund Amount:'
                                         value={values?.calcResults?.interestRefundAmt} />
                    <CalcResultsTableRow label='Total Refund Due:' value={values?.calcResults?.totalRefundAmt}
                                         className={classes?.tableCellTotal} />
                  </TableBody>
                </Table>
              </LabeledOutline>
            </Grid>
          </Grid>
        </CardContentInner>
        <Toolbar>
          <SanitizedBox component='span' mr={2} mb={1.5}>
            <Button
              variant='contained'
              color='primary'
              label='Cancel'
              startIcon={<CancelIcon />}
              onClick={HandleClose}>
              Cancel
            </Button>
          </SanitizedBox>
          <SanitizedBox component='span' mr={2} mb={1.5}>
            <Button
              variant='contained'
              color='primary'
              label='Print'
              startIcon={<PrintIcon />}
              onClick={HandlePrint}
            >
              Print
            </Button>
          </SanitizedBox>
        </Toolbar>
      </Dialog>
    );
  }
}

export default withStyles(styles)(PrintCalculationClassBased);
